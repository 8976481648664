<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Holiday</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/holiday-groups/">Holiday Groups</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'holidays', params: { holiday_group: this.$route.params.holiday_group }}">
                        <span>Holidays</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:submit.prevent="createHoliday()">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Holiday Group
                        </label>

                        <div class="col-md-9">{{holiday_group}}</div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>

                        <div class="col-md-9">
                            <input
                                v-model="holiday.name"
                                @change="textTitleCase($event, 'name')"
                                type="text"
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Date
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-input-group size="md" class="mb-1em" placeholder="dvg">
                                <date-pick
                                    v-model="holiday.date"
                                    :inputAttributes="{readonly: true, placeholder: 'Select Date'}"
                                    :selectableYearRange="{from: from_date, to: to_date}"
                                    :displayFormat="'DD-MM-YYYY'"
                                >
                                </date-pick>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="createHoliday()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
export default {
    components: {
        NotFound,
        DatePick
    },
    data() {
        return {
            holiday_groups: [],
            holiday:{
                name: "",
                holiday_group_id:"",
                data:"",
            },
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            holiday_group:"",
            from_date: new Date().getFullYear(),
            to_date: new Date().getFullYear() + 1, 
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_HOLIDAYS");
        this.holiday.holiday_group_id = this.$route.params.holiday_group
        if (this.hasPermission("CAN_MANAGE_HOLIDAYS")) {
            this.getHolidayGroups(this.holiday.holiday_group_id );
        }
        
       
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.holiday[key] = this.strTitleCase(event.target.value);
        },

        getHolidayGroups() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.holiday_group
            };
            this.axios.get(this.$api.get_holiday_groups, query).then(response => {
                this.holiday.holiday_group_id = response.data.data.id;
                this.holiday_group = response.data.data.name;
                
                this.updateProgressBar(true);
            })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        createHoliday() {
            this.startProgressBar();
            this.axios
                .post(this.$api.create_holiday, this.holiday, this.getAuthHeaders())
                .then(() => {
                    this.updateProgressBar(true);
                    this.api_error = "";
                    this.$router.push({name: 'holidays', params: { holiday_group: this.$route.params.holiday_group }});
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>
